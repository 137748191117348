import React from "react";
import PropTypes from "prop-types";
import MetricCards from "./MetricCards";
import ScrubbedAmountsByQuarter from "./ScrubbedAmountsByQuarter";
import ScrubTypeBreakdown from "./ScrubTypeBreakdown";
import CustomerPerformance from "./CustomerPerformance";
import ScrubRatesOfHighestVolumeCustomers from "./ScrubRatesOfHighestVolumeCustomers";
import RecentInvoices from "./RecentInvoices";
import ProductDistribution from "./ProductDistribution";

const Dashboard = ({ currentQuarter, recentInvoices }) => {
  return (
    <div className="dashboard main-content__body">
      <div className="cards-container cards-container--row">
        <MetricCards currentQuarter={currentQuarter} />
      </div>
      <RecentInvoices recentInvoices={recentInvoices} />
      <div className="cards-container cards-container--row">
        <ScrubbedAmountsByQuarter />
        <ScrubRatesOfHighestVolumeCustomers />
      </div>
      <div className="cards-container cards-container--row">
        <ScrubTypeBreakdown currentQuarter={currentQuarter} />
        <CustomerPerformance />
      </div>
      <ProductDistribution currentQuarter={currentQuarter} />
    </div>
  );
};

Dashboard.propTypes = {
  currentQuarter: PropTypes.string.isRequired,
  recentInvoices: PropTypes.shape({
    pendingReview: PropTypes.array.isRequired,
    finalized: PropTypes.array.isRequired,
  }),
};

export default Dashboard;
