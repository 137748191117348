import React from "react";
import PropTypes from "prop-types";
import TableLoadingSkeleton from "../../common/TableLoadingSkeleton";
import { formatCurrency } from "../../utils/numberUtils";

const TOTAL_SCRUBBED_AMOUNT = "Total Scrubbed Amount";

const ScrubResultsTable = ({ data, isLoading, colors }) => {
  let scrubIndicatorColorIndex = 0;

  const shouldShowScrubIndicator = (scrubType) => scrubType !== TOTAL_SCRUBBED_AMOUNT;

  const renderScrubIndicator = () => {
    const backgroundColor = colors[scrubIndicatorColorIndex];
    scrubIndicatorColorIndex++;

    return (
      <span
        className="row-indicator"
        style={{
          backgroundColor,
        }}
        data-testid="row-indicator"
      ></span>
    );
  };

  const renderTableHeader = () => (
    <thead>
      <tr>
        <th width={170}>Validation</th>
        {data?.headers?.map((header) => (
          <th key={header} className="align-right">
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderTableBody = () => {
    if (isLoading) return <TableLoadingSkeleton columns={5} rows={8} />;
    return (
      <tbody>
        {data?.rows?.map(
          (row, rowIndex, rows) =>
            rowIndex < rows.length - 1 && (
              <tr
                key={`row-${rowIndex}`}
                className={row.scrubType === TOTAL_SCRUBBED_AMOUNT ? "bold" : ""}
              >
                <td>
                  {shouldShowScrubIndicator(row.scrubType) ? renderScrubIndicator() : null}
                  {row.scrubType}
                </td>

                {data?.headers?.map((header) => (
                  <td className="align-right" key={header}>
                    {formatCurrency(row[header] || 0, true)}
                  </td>
                ))}
              </tr>
            ),
        )}
      </tbody>
    );
  };

  const renderTableFooter = () => (
    <tfoot>
      {data?.rows?.length > 0 && (
        <tr key="tfoot-last-row">
          <td>
            <strong>{data.rows[data.rows.length - 1].scrubType}</strong>
          </td>
          {data?.headers?.map((header) => (
            <td className="align-right" key={header}>
              <strong>{formatCurrency(data.rows[data.rows.length - 1][header] || 0, true)}</strong>
            </td>
          ))}
        </tr>
      )}
    </tfoot>
  );

  return (
    <div style={{ width: "calc(60% - 24px)", marginLeft: 60 }}>
      <div className="table--scroll">
        <table className="table table--basic table--layout-fixed">
          {renderTableHeader()}
          {renderTableBody()}
          {renderTableFooter()}
        </table>
      </div>
    </div>
  );
};

ScrubResultsTable.propTypes = {
  data: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  isLoading: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default ScrubResultsTable;
