import React from "react";
import PropTypes from "prop-types";
import NameValuePair from "../../common/NameValuePair";
import { formatCurrency, formatToPercent } from "../../utils/numberUtils";
import { formatDateLongWithFullMonth } from "../../utils/dateTimeUtils";
import InvoiceStatusBadge from "../InvoiceStatusBadge";

const Metadata = ({ invoice }) => {
  const {
    scrubbedAmount,
    totalAmount,
    scrubRate,
    validatedRebateAmount,
    date,
    dueDate,
    status,
    finalizedAt,
  } = invoice || {};

  return (
    <div className="card metadata">
      <div className="metadata__main">
        <NameValuePair name="Scrubbed Amount" value={formatCurrency(scrubbedAmount)} isLargeValue />
        <div className="metadata__group">
          <NameValuePair name="Total Invoice Amount" value={formatCurrency(totalAmount)} />
          <NameValuePair name="Scrub Rate" value={formatToPercent(scrubRate, 1)} />
          <NameValuePair name="Validated Amount" value={formatCurrency(validatedRebateAmount)} />
        </div>
      </div>
      <div className="metadata__group">
        <NameValuePair name="Invoice Date" value={formatDateLongWithFullMonth(date)} />
        <NameValuePair name="Due Date" value={formatDateLongWithFullMonth(dueDate)} />
      </div>
      <div className="metadata__group">
        <NameValuePair
          name="Date Finalized"
          value={finalizedAt ? formatDateLongWithFullMonth(finalizedAt) : "--"}
          testId="date-finalized-name-value"
        />
        <NameValuePair
          name="Status"
          value={<InvoiceStatusBadge status={status} />}
          testId="status-name-value"
        />
      </div>
    </div>
  );
};

Metadata.propTypes = {
  invoice: PropTypes.shape({
    number: PropTypes.string,
    date: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    validatedRebateAmount: PropTypes.number.isRequired,
    scrubbedAmount: PropTypes.number.isRequired,
    scrubRate: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    finalizedAt: PropTypes.string,
    customerName: PropTypes.string,
  }).isRequired,
};

export default Metadata;
