import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import debounce from "debounce-promise";
import dayjs from "dayjs";
import InvoicesTable from "./InvoicesTable";
import Search from "../common/Search";
import SelectSearch from "../common/SelectSearch";
import Notice from "../common/Notice";
import useFetch from "../hooks/useFetch";
import { Add } from "../common/icons";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";
import { createQueryString } from "../utils/urlUtils";

const Invoices = ({ customerNames = [] }) => {
  const [invoices, setInvoices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [customerFilter, setCustomerFilter] = useState(undefined);
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });
  const isInitialLoad = useRef(true);

  const { isLoading, data: initialInvoices, error } = useFetch("/api/invoices");
  const {
    get: checkProcessingInvoices,
    isLoading: isCheckProcessingInvoicesPending,
    data: refetchedProcessingInvoices,
  } = useFetch();

  useEffect(() => {
    if (!initialInvoices) return;

    setInvoices(initialInvoices);
  }, [initialInvoices]);

  useEffect(() => {
    const hasActiveGetRequest = isCheckProcessingInvoicesPending && !isInitialLoad.current;
    if (!invoices || invoices?.length === 0 || hasActiveGetRequest) return;

    const invoicesToTrack = invoices.filter(
      (invoice) =>
        isRecentlyPendingWithStatus(invoice, "Pending") ||
        isRecentlyPendingWithStatus(invoice, "Processing"),
    );
    if (invoicesToTrack?.length === 0) return;

    const queryString = createQueryString({ ids: invoicesToTrack?.map((invoice) => invoice.id) });

    if (isInitialLoad.current) isInitialLoad.current = false;
    const trackInvoices = setTimeout(() => {
      checkProcessingInvoices(`/api/invoices/status${queryString}`);
    }, 5000);

    return () => {
      clearTimeout(trackInvoices);
    };
  }, [invoices, isCheckProcessingInvoicesPending]);

  useEffect(() => {
    if (!refetchedProcessingInvoices || refetchedProcessingInvoices?.length === 0) return;

    let includesUpdatedInvoice = false;
    const invoicesModified = invoices?.map((invoice) => {
      const refetchedInvoice = refetchedProcessingInvoices.find((u) => invoice.id === u.id);
      const isInvoiceStatusUnchanged =
        refetchedInvoice && refetchedInvoice.status === invoice.status;
      if (!refetchedInvoice || isInvoiceStatusUnchanged) return invoice;

      includesUpdatedInvoice = true;
      return refetchedInvoice;
    });

    if (includesUpdatedInvoice) setInvoices(invoicesModified);
  }, [refetchedProcessingInvoices]);

  useEffect(() => {
    if (error) {
      setNotice({
        kind: "error",
        open: true,
        message: "Error retrieving invoice data",
      });
    }
  }, [isLoading]);

  function isRecentlyPendingWithStatus(invoice, status) {
    if (!invoice || !status) return false;

    return invoice.status === status && isLessThanOneHourOld(invoice.created_at);
  }

  function isLessThanOneHourOld(dateTime) {
    if (!dateTime) return false;

    return dayjs(dateTime).isAfter(dayjs().subtract(1, "hour"));
  }

  function updateSearchQuery(e) {
    setSearchQuery(e.target.value);
  }

  const customerOptions = [{ value: "", label: "View All" }].concat(
    customerNames.map((name) => ({ value: name, label: name })),
  );

  const statusOptions = [
    { value: "", label: "View All" },
    { value: "Finalized", label: "Finalized" },
    { value: "Pending Review", label: "Pending Review" },
    { value: "Processing", label: "Processing" },
    { value: "Upload Error", label: "Upload Error" },
    { value: "Scrub Error", label: "Scrub Error" },
  ];

  const DEBOUNCE_TIME = 500;

  return (
    <div className="main-content">
      <div className="main-content__header">
        <h1 className="main-content__heading">Invoices</h1>
      </div>
      <div className="main-content__body">
        <div className="action-bar  action-bar--align-items-flex-end action-bar--mb-16">
          <Search
            className="search--md"
            style={{ flexShrink: 0 }}
            placeholder="Search for a Customer or Invoice Number"
            onChange={debounce(updateSearchQuery, DEBOUNCE_TIME)}
          />
          <div>
            <label className="form__content__label" htmlFor="customer">
              Customer
            </label>
            <SelectSearch
              inputId="customer"
              name="customer"
              testId="customer-filter"
              options={customerOptions}
              defaultValue={customerOptions[0]}
              value={customerFilter || customerOptions[0]}
              size="md"
              onChange={(option) => setCustomerFilter(option)}
              disableDefaultSort
            />
          </div>
          <div>
            <label className="form__content__label" htmlFor="status">
              Status
            </label>
            <SelectSearch
              inputId="status"
              name="status"
              testId="status-filter"
              options={statusOptions}
              defaultValue={statusOptions[0]}
              value={statusFilter || statusOptions[0]}
              size="md"
              onChange={(option) => setStatusFilter(option)}
              disableDefaultSort
            />
          </div>
          <a className="btn action-bar__item--right" href="/invoices/new">
            <Add color={colors.white} size={20} />
            <span>Add New Invoice</span>
          </a>
        </div>
        <InvoicesTable
          invoices={invoices}
          isLoading={isLoading}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          customerFilter={customerFilter?.value}
          statusFilter={statusFilter?.value}
          setNotice={setNotice}
        />
      </div>
      <Notice details={notice} />
    </div>
  );
};

Invoices.propTypes = {
  customerNames: PropTypes.arrayOf(PropTypes.string),
};

export default Invoices;
