import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDateLong } from "../utils/dateTimeUtils";
import { formatCurrency } from "../utils/numberUtils";
import { numberSortFn } from "../utils/tanstackCustomFns";
import InvoiceStatusBadge from "./InvoiceStatusBadge";
import Table from "../common/Table";

function InvoicesTable({
  invoices = [],
  isLoading,
  searchQuery,
  setSearchQuery,
  customerFilter = "",
  statusFilter = "",
  setNotice,
}) {
  const renderActions = (info) => {
    const invoiceId = info.row.original.id;
    const value = info.getValue();

    const isPendingReview = value === "Pending Review";
    const isFinalized = value === "Finalized";

    return (
      <div style={{ display: "flex" }}>
        {(isPendingReview || isFinalized) && (
          <a
            className="btn btn--secondary btn--small"
            style={{ marginRight: "8px" }}
            href={`/invoices/${invoiceId}`}
          >
            View Details
          </a>
        )}
        {isFinalized && (
          <a
            className="btn btn--secondary btn--small"
            href={`/invoices/${invoiceId}/export`}
            data-turbo="false"
          >
            Export
          </a>
        )}
      </div>
    );
  };
  const filterSetup = useMemo(
    () => [
      {
        columnName: "customer_name",
        filterValue: customerFilter,
      },
      {
        columnName: "status",
        filterValue: statusFilter,
      },
    ],
    [customerFilter, statusFilter],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("customer.name", {
        header: "Customer",
        cell: (info) => info.getValue(),
        filterFn: "equalsString",
        size: 96,
      }),
      columnHelper.accessor("number", {
        header: "Invoice Number",
        cell: (info) => info.getValue(),
        size: 96,
      }),
      columnHelper.accessor("date", {
        header: "Invoice Date",
        cell: (info) => formatDateLong(info.getValue()),
        size: 106,
        enableGlobalFilter: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("total_amount", {
        header: "Total Invoice Amount",
        cell: (info) => formatCurrency(info.getValue()),
        size: 140,
        enableGlobalFilter: false,
        sortingFn: numberSortFn,
        sortDescFirst: true,
      }),
      columnHelper.accessor("scrubbed_amount", {
        header: "Scrubbed Amount",
        cell: (info) => formatCurrency(info.getValue()),
        enableGlobalFilter: false,
        sortingFn: numberSortFn,
        sortDescFirst: true,
        size: 96,
      }),
      columnHelper.accessor("due_date", {
        header: "Due Date",
        cell: (info) => formatDateLong(info.getValue()),
        size: 96,
        enableGlobalFilter: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => <InvoiceStatusBadge status={info.getValue()} />,
        size: 136,
        enableGlobalFilter: false,
        filterFn: "equalsString",
      }),
      columnHelper.accessor("finalized_at", {
        header: "Date Finalized",
        cell: (info) => (info.getValue() ? formatDateLong(info.getValue()) : "--"),
        enableGlobalFilter: false,
        sortDescFirst: true,
        size: 124,
      }),
      columnHelper.accessor("created_at", {
        header: "Date Uploaded",
        cell: (info) => formatDateLong(info.getValue()),
        enableGlobalFilter: false,
        sortDescFirst: true,
        size: 124,
      }),
      columnHelper.accessor("status", {
        id: "status-actions",
        header: undefined,
        cell: (info) => renderActions(info),
        enableGlobalFilter: false,
        enableSorting: false,
        size: 218,
      }),
    ];
  }, [invoices]);

  const tableOptions = {
    initialState: {
      sorting: [
        {
          id: "created_at",
          desc: true,
        },
      ],
    },
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={invoices}
        columns={columns}
        tableOptions={tableOptions}
        noDataMessage="No invoices have been created"
        isLoading={isLoading}
        searchQuery={searchQuery}
        filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
      />
    </div>
  );
}

InvoicesTable.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      customer: PropTypes.shape({
        name: PropTypes.string,
      }),
      number: PropTypes.string,
      date: PropTypes.string,
      due_date: PropTypes.string,
      total_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      validated_rebate_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      status: PropTypes.string,
      created_at: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  customerFilter: PropTypes.string,
  statusFilter: PropTypes.string,
  setNotice: PropTypes.func.isRequired,
};

export default InvoicesTable;
