import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "../../common/Breadcrumbs";
import Metadata from "./Metadata";
import Tabs from "../../common/Tabs";
import Summary from "./Summary";
import Spreadsheet from "./Spreadsheet";
import Modal from "../../common/Modal";
import Notice from "../../common/Notice";
import useFetch from "../../hooks/useFetch";
import { Download } from "../../common/icons";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const tabItems = {
  SUMMARY: "Summary",
  SPREADSHEET: "Line View",
};

const InvoiceDetails = ({ invoice: initialInvoice, products, scrubRules }) => {
  const [selectedTab, setSelectedTab] = useState(tabItems.SUMMARY);
  const [invoice, setInvoice] = useState(initialInvoice);
  const [displayModal, setDisplayModal] = useState(false);
  const [notice, setNotice] = useState({ kind: "error", open: false, message: "" });

  const { data: updatedInvoice, error, patch } = useFetch();

  useEffect(() => {
    if (updatedInvoice) setInvoice(updatedInvoice);
  }, [updatedInvoice]);

  useEffect(() => {
    if (error) {
      setNotice({
        kind: "error",
        open: true,
        message: "There was an error finalizing this invoice.",
      });
    }
  }, [error]);

  const isFinalizeBtnDisabled = invoice.status !== "Pending Review";
  const isExportBtnDisabled = invoice.status !== "Finalized";

  function finalizeInvoice() {
    patch(`/api/invoices/${invoice.id}/finalize_invoice`);
    setDisplayModal(false);
  }

  return (
    <div className="main-content">
      <div className="main-content__header">
        <Breadcrumbs
          breadcrumbs={["Invoices", "Invoice Details"]}
          handleBreadcrumbClick={() => (window.location.href = "/invoices")}
        />
        <div className="main-content__header__row main-content__header__row--space-between">
          <h1 className="main-content__heading">{`${invoice.customerName} ${invoice.number}`}</h1>
          <div className="main-content__header__row__item">
            <button
              className="btn"
              onClick={() => setDisplayModal(true)}
              disabled={isFinalizeBtnDisabled}
            >
              Finalize Invoice
            </button>
            <button
              className="btn"
              onClick={() => (window.location.href = `/invoices/${invoice.id}/export`)}
              disabled={isExportBtnDisabled}
            >
              <Download
                color={isExportBtnDisabled ? colors.secondaryText : colors.white}
                size={20}
              />
              <span>Export</span>
            </button>
          </div>
        </div>
      </div>
      <div className="main-content__body" style={{ overflowY: "hidden" }}>
        <Metadata invoice={invoice} />
        <Tabs
          items={Object.values(tabItems)}
          selectedItem={selectedTab}
          setSelectedItem={setSelectedTab}
        />
        <div
          style={{
            display: selectedTab === tabItems.SUMMARY ? "block" : "none",
            overflowY: "auto",
          }}
        >
          <Summary invoiceId={invoice.id} products={products} />
        </div>
        <div
          style={{
            display: selectedTab === tabItems.SPREADSHEET ? "flex" : "none",
            flexDirection: "column",
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          <Spreadsheet
            invoiceStatus={invoice.status}
            invoiceId={invoice.id}
            scrubRules={scrubRules}
            isDisplayed={selectedTab === tabItems.SPREADSHEET}
          />
        </div>
      </div>
      <Modal
        title="Finalize Invoice"
        text="By finalizing this invoice, you are confirming that you have reviewed the results and want to generate the output file. You will not be able to make any more changes to the invoice."
        buttonLabel="Finalize Invoice"
        handleCancel={() => setDisplayModal(false)}
        handleSubmit={finalizeInvoice}
        displayModal={displayModal}
        testId="finalize-invoice-modal"
      />
      <Notice details={notice} />
    </div>
  );
};

InvoiceDetails.propTypes = {
  invoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    validatedRebateAmount: PropTypes.number.isRequired,
    scrubbedAmount: PropTypes.number.isRequired,
    scrubRate: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    finalizedAt: PropTypes.string,
    customerName: PropTypes.string.isRequired,
  }).isRequired,
  products: PropTypes.array.isRequired,
  scrubRules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
};

export default InvoiceDetails;
