import React from "react";
import PropTypes from "prop-types";
import * as RadixSelect from "@radix-ui/react-select";

const Select = ({
  inputId = "",
  options,
  placeholder = "Select",
  value,
  onChange,
  testId = "",
  className = "",
  emptyOptionLabel = "",
  disabled = false,
  disableDefaultSort = false,
}) => {
  const sortedOptions = disableDefaultSort
    ? options
    : options.sort((a, b) => a.label?.localeCompare(b.label));

  return (
    <div className={`select ${className}`}>
      <RadixSelect.Root value={value} onValueChange={onChange} disabled={disabled}>
        <RadixSelect.Trigger
          id={inputId}
          className="select__trigger"
          title={options.find((option) => option.value === value)?.label || emptyOptionLabel}
          data-testid={testId}
        >
          <RadixSelect.Value placeholder={placeholder} />
          <RadixSelect.Icon className="select__trigger__icon">
            <span className="material-symbols-rounded">keyboard_arrow_down</span>
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Content className="select__content" position="popper">
          <RadixSelect.ScrollUpButton className="select__content__scroll-btn">
            <span className="material-symbols-rounded">arrow_drop_up</span>
          </RadixSelect.ScrollUpButton>
          <RadixSelect.Viewport>
            {emptyOptionLabel && (
              <SelectItem value={null} emptyOptionLabel={emptyOptionLabel}>
                {emptyOptionLabel}
              </SelectItem>
            )}
            {sortedOptions.map(({ value, label }, index) => (
              <SelectItem key={`${value}-${index}`} value={value}>
                {label}
              </SelectItem>
            ))}
          </RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton className="select__content__scroll-btn">
            <span className="material-symbols-rounded">arrow_drop_down</span>
          </RadixSelect.ScrollDownButton>
        </RadixSelect.Content>
      </RadixSelect.Root>
    </div>
  );
};

const SelectItem = ({ children, emptyOptionLabel = "", ...props }) => {
  return (
    <RadixSelect.Item
      className={`select__item ${children === emptyOptionLabel ? "select__item--empty" : ""}`}
      {...props}
    >
      <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
      <RadixSelect.ItemIndicator className="select__item__indicator">
        <span className="material-symbols-rounded">check</span>
      </RadixSelect.ItemIndicator>
    </RadixSelect.Item>
  );
};

Select.propTypes = {
  inputId: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  emptyOptionLabel: PropTypes.string,
  disableDefaultSort: PropTypes.bool,
};

SelectItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  emptyOptionLabel: PropTypes.string,
};

export default Select;
