import React from "react";
import PropTypes from "prop-types";
import Badge from "../common/Badge";

const InvoiceStatusBadge = ({ status }) => {
  const COLORS = {
    Finalized: "dark-green",
    "Pending Review": "bright-purple",
    Processing: "golden-yellow",
    "Upload Error": "notice",
    "Scrub Error": "notice",
  };

  return <Badge color={COLORS[status] || null}>{status}</Badge>;
};

InvoiceStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default InvoiceStatusBadge;
