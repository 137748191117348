import React, { useState, useEffect, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import useFetch from "../hooks/useFetch";
import Table from "../common/Table";
import Notice from "../common/Notice";
import { formatCurrency, formatToPercent } from "../utils/numberUtils";
import { isValidNumber } from "../utils/validationUtils";

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const headerProps = { className: "align-right" };
const cellProps = { className: "align-right-sort" };

const tableOptions = {
  initialState: {
    sorting: [
      {
        id: "totalInvoiceAmount",
        desc: true,
      },
    ],
  },
};

const CustomerPerformance = () => {
  const [notice, setNotice] = useState(initialNoticeState);
  const { data, isLoading, error } = useFetch("/api/dashboard/customer_performance");

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("customer", {
        header: "Customer",
        cell: (info) => info.getValue(),
        size: 100,
      }),
      columnHelper.accessor("scrubbedAmount", {
        header: () => (
          <div>
            Scrubbed
            <br /> Amount
          </div>
        ),
        cell: (info) => formatCurrency(info.getValue(), true),
        size: 110,
        headerProps,
        cellProps,
      }),
      columnHelper.accessor("totalInvoiceAmount", {
        header: () => (
          <div>
            Total Invoice
            <br /> Amount
          </div>
        ),
        cell: (info) => formatCurrency(info.getValue(), true),
        size: 115,
        headerProps,
        cellProps,
      }),
      columnHelper.accessor("scrubRate", {
        header: () => (
          <div>
            Scrub
            <br /> Rate
          </div>
        ),
        cell: (info) => {
          const value = info.getValue();
          return isValidNumber(value) ? formatToPercent(value, 1) : "N/A";
        },
        size: 72,
        headerProps,
        cellProps,
      }),
    ];
  }, [data]);

  useEffect(() => {
    if (error) {
      setNotice({
        ...initialNoticeState,
        open: true,
        message: "Error retrieving customer performance data",
      });
    }
  }, [error]);

  return (
    <div className="card">
      <div className="card__title">Customer Performance (Last 4 Quarters)</div>
      <div className="table--scroll">
        <Table
          isLoading={isLoading}
          columns={columns}
          data={data}
          tableOptions={tableOptions}
          styleVariant="table--basic"
          layoutFixed
        />
      </div>
      <Notice details={notice} />
    </div>
  );
};

export default CustomerPerformance;
