import React from "react";
import { propTypes, defaultProps } from "./iconProps";

const Calendar = ({ color = defaultProps.color, size = defaultProps.size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    height={size}
    width={size}
    fill={color}
  >
    <path d="M15.833 3.333H15V1.666h-1.667v1.667H6.667V1.666H5v1.667h-.833A1.66 1.66 0 0 0 2.508 5L2.5 16.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.666V5c0-.917-.75-1.667-1.667-1.667zm0 13.334H4.167V8.332h11.666v8.333zm0-10H4.167V5h11.666v1.667zm-8.333 5H5.833V10H7.5v1.666zm3.333 0H9.167V10h1.666v1.666zm3.334 0H12.5V10h1.667v1.666zM7.5 15H5.833v-1.667H7.5V15zm3.333 0H9.167v-1.667h1.666V15zm3.334 0H12.5v-1.667h1.667V15z" />
  </svg>
);

Calendar.propTypes = propTypes;

export default Calendar;
