import React, { useState, useEffect, useMemo, memo } from "react";
import PropTypes from "prop-types";
import { createColumnHelper } from "@tanstack/react-table";
import reoInvoiceColumns from "../reoInvoiceColumns";
import TableVirtualized from "../../common/TableVirtualized";
import { CancelCircle, CheckCircle } from "../../common/icons";
import Tooltip from "../../common/Tooltip";
import Badge from "../../common/Badge";
import Notice from "../../common/Notice";
import MultiSelect from "../../common/MultiSelect";
import useFetch from "../../hooks/useFetch";
import textWidth from "../../utils/textWidth";
import { arrIncludesSomeWithEmpty } from "../../utils/tanstackCustomFns";
import { extractValues } from "../../utils/arrayUtils";
import { formatCurrency, addCommasToNumber } from "../../utils/numberUtils";
import { isEmptyValue, isValidNumber } from "../../utils/validationUtils";

const PADDING_OFFSET = 24;
const FONT_SIZE = 12;
const FONT_FAMILY = "Roboto, sans-serif";

const alignRight = (type) => ["number", "currency", "date"].includes(type);

const filterTypes = {
  VALIDATION_STATUS: "validationStatus",
  SCRUB_TYPE: "scrubType",
  PLAN_NAME: "planName",
  NDC: "ndc",
  SPID: "spid",
  PRODUCT_BRAND: "productBrand",
};

const renderValidationStatus = (info) => {
  const passed = info.getValue();

  if (passed) {
    return <CheckCircle size={20} />;
  }

  const failedScrubRules = info.row.original["failed_scrub_rules"];

  return (
    <Tooltip
      trigger={
        <div>
          <Badge color="notice" enableHover>
            {failedScrubRules.length}
          </Badge>
        </div>
      }
      align="start"
      alignOffset={50}
      contentClass="tooltip__content--width-auto"
    >
      <div className="tooltip__content__title">Scrub Type</div>
      <div className="tooltip__content__details" style={{ gap: 8 }}>
        {failedScrubRules.map(({ label }) => (
          <div key={label}>
            <Badge color="notice">{label}</Badge>
          </div>
        ))}
      </div>
    </Tooltip>
  );
};

const renderScrubStatus = (scrubPassed) => {
  const leftMargin = {
    style: {
      marginLeft: "8px",
    },
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {scrubPassed ? (
        <>
          <CheckCircle size={20} />
          <div {...leftMargin}>Pass</div>
        </>
      ) : (
        <>
          <CancelCircle size={20} />
          <div {...leftMargin}>Fail</div>
        </>
      )}
    </div>
  );
};

const INITIAL_FILTERS = {
  validationStatus: [],
  scrubType: [],
  planName: [],
  ndc: [],
  spid: [],
  productBrand: [],
};

const Spreadsheet = ({ invoiceId, invoiceStatus, scrubRules = [], isDisplayed }) => {
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving invoice line data",
  });
  const [filteredRows, setFilteredRows] = useState([]);

  const { isLoading, data, error } = useFetch(`/api/invoices/${invoiceId}/invoice_lines`);

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  const validationStatusOptions = [
    { value: true, label: "Validated" },
    { value: false, label: "Scrubbed" },
  ];
  const scrubTypeOptions = scrubRules.map((rule) => ({ value: rule.id, label: rule.type }));
  const planNameOptions = useMemo(() => getUniqueOptions(data, "plan_name"), [data]);
  const ndcOptions = useMemo(() => getUniqueOptions(data, "ndc11"), [data]);
  const spidOptions = useMemo(() => getUniqueOptions(data, "service_provider_npi"), [data]);
  const productBrandOptions = useMemo(() => getUniqueOptions(data, "product_brand"), [data]);

  const filterSetup = useMemo(() => {
    return [
      {
        id: "validation_status",
        value: {
          filteredValidationStatuses: extractValues(filters.validationStatus),
          filteredScrubTypes: extractValues(filters.scrubType),
        },
      },
      { id: "plan_name", value: extractValues(filters.planName) },
      { id: "ndc11", value: extractValues(filters.ndc) },
      { id: "product_brand", value: extractValues(filters.productBrand) },
      { id: "service_provider_npi", value: extractValues(filters.spid) },
    ];
  }, [filters]);

  const hasFiltersApplied = Object.values(filters).some((filter) => filter.length > 0);

  const totalValidatedAmount = useMemo(() => {
    return sumColumnValues("validated_amount");
  }, [filteredRows]);

  const totalScrubbedAmount = useMemo(() => {
    return sumColumnValues("scrubbed_amount");
  }, [filteredRows]);

  function sumColumnValues(columnId) {
    return filteredRows?.reduce((accumulatedTotal, { original }) => {
      const currentValue = parseFloat(original[columnId]);

      return isValidNumber(currentValue) ? accumulatedTotal + currentValue : accumulatedTotal;
    }, 0);
  }

  function getUniqueOptions(data, key) {
    const optionsSet = new Set(
      data?.map((datum) => (isEmptyValue(datum[key]) ? "(Blank)" : datum[key])),
    );
    const optionsArray = Array.from(optionsSet);
    return optionsArray?.map((val) => ({ label: val, value: val === "(Blank)" ? null : val }));
  }

  function validationStatusFilterFn(row, _, filters) {
    const { validation_status, failed_scrub_rules } = row.original;
    const { filteredValidationStatuses, filteredScrubTypes } = filters;

    let hasMatchingValidationStatus = true;
    if (filteredValidationStatuses?.length) {
      hasMatchingValidationStatus = filteredValidationStatuses.includes(validation_status);
    }

    let hasMatchingFailedScrub = true;
    if (filteredScrubTypes?.length) {
      const hasFailedScrubs = failed_scrub_rules?.length > 0;
      const firstFailedScrub = failed_scrub_rules?.[0];

      hasMatchingFailedScrub = hasFailedScrubs && filteredScrubTypes.includes(firstFailedScrub.id);
    }

    return hasMatchingValidationStatus && hasMatchingFailedScrub;
  }

  function handleFilterChange(filter, value) {
    setFilters((prevFilters) => ({ ...prevFilters, [filter]: value }));
  }

  const pinnedColumnDefinitions = [
    {
      id: "validation_status",
      type: "string",
      header: () => (
        <div>
          Validation
          <br /> Status
        </div>
      ),
      cell: (info) => renderValidationStatus(info),
      size: 100,
      className: "icon-cell",
      filterFn: validationStatusFilterFn,
    },
    {
      id: "scrubbed_amount",
      type: "currency",
      header: () => (
        <div>
          Scrubbed
          <br /> Amount
        </div>
      ),
      cell: (info) => formatCurrency(info.getValue()),
      size: 100,
    },
    {
      id: "validated_amount",
      type: "currency",
      header: () => (
        <div>
          Validated
          <br /> Amount
        </div>
      ),
      cell: (info) => formatCurrency(info.getValue()),
      size: 100,
    },
    {
      id: "coverage_status",
      type: "string",
      header: () => (
        <div>
          Coverage
          <br /> Status
        </div>
      ),
      cell: (info) => info.getValue() || "--",
      className: "pinned--shadow",
      size: 100,
    },
  ];

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    let pinnedLeftOffset = 0;

    const pinnedColumns = pinnedColumnDefinitions.map(
      ({ id, header, cell, filterFn, size, type, style = {}, className = "" }) => {
        const column = columnHelper.accessor(id, {
          header,
          cell,
          size,
          headerProps: {
            style: { left: pinnedLeftOffset },
            className: `pinned ${className} ${alignRight(type) ? "align-right" : ""}`,
          },
          cellProps: {
            style: { left: pinnedLeftOffset, ...style },
            className: `pinned ${className} ${alignRight(type) ? "table__column--align-right-sort" : ""}`,
          },
          ...(filterFn && { filterFn }),
        });

        pinnedLeftOffset += size + PADDING_OFFSET;
        return column;
      },
    );

    const reoColumns = reoInvoiceColumns.map(({ value, label, type, width }) => {
      return columnHelper.accessor(value, {
        header: label,
        cell: (info) => {
          const cellValue = info.getValue();

          if (type === "currency") {
            return formatCurrency(cellValue);
          } else if (type === "number") {
            return addCommasToNumber(cellValue);
          }

          return cellValue || "--";
        },
        size: width,
        headerProps: {
          className: alignRight(type) ? "align-right" : "",
        },
        cellProps: {
          className: alignRight(type) ? "table__column--align-right-sort" : "",
        },
        ...(["ndc11", "plan_name", "service_provider_npi"].includes(value)
          ? { filterFn: arrIncludesSomeWithEmpty }
          : {}),
      });
    });

    const scrubRuleColumns = scrubRules?.map(({ id, type }) => {
      return columnHelper.accessor((row) => row.line_scrub_rules[id], {
        header: type,
        cell: (info) => renderScrubStatus(info.getValue()),
        cellProps: {
          className: "icon-cell",
        },
        size: textWidth(type, FONT_SIZE, FONT_FAMILY) + PADDING_OFFSET + 20, // 20 is the width of the icon
      });
    });

    const productColumn = [
      columnHelper.accessor("product_brand", {
        cell: (info) => info.getValue(),
        filterFn: arrIncludesSomeWithEmpty,
      }),
    ];

    return [...pinnedColumns, ...reoColumns, ...scrubRuleColumns, ...productColumn];
  }, []);

  const tableOptions = { state: { columnVisibility: { product_brand: false } } };

  return (
    <>
      <div className="action-bar">
        <MultiSelect
          label="Validation Status"
          options={validationStatusOptions}
          selectedOptions={filters.validationStatus}
          onChange={(selected) => handleFilterChange(filterTypes.VALIDATION_STATUS, selected)}
          onClear={() => handleFilterChange(filterTypes.VALIDATION_STATUS, [])}
        />
        <MultiSelect
          label="Scrub Type"
          options={scrubTypeOptions}
          selectedOptions={filters.scrubType}
          onChange={(selected) => handleFilterChange(filterTypes.SCRUB_TYPE, selected)}
          onClear={() => handleFilterChange(filterTypes.SCRUB_TYPE, [])}
          disableDefaultSort
        />
        <MultiSelect
          key={`plan-name-${planNameOptions.length}`}
          label="Plan Name"
          options={planNameOptions}
          selectedOptions={filters.planName}
          onChange={(selected) => handleFilterChange(filterTypes.PLAN_NAME, selected)}
          onClear={() => handleFilterChange(filterTypes.PLAN_NAME, [])}
        />
        <MultiSelect
          key={`ndc-${ndcOptions.length}`}
          label="NDC"
          options={ndcOptions}
          selectedOptions={filters.ndc}
          onChange={(selected) => handleFilterChange(filterTypes.NDC, selected)}
          onClear={() => handleFilterChange(filterTypes.NDC, [])}
        />
        <MultiSelect
          key={`product-brand-${productBrandOptions.length}`}
          label="Product"
          options={productBrandOptions}
          selectedOptions={filters.productBrand}
          onChange={(selected) => handleFilterChange(filterTypes.PRODUCT_BRAND, selected)}
          onClear={() => handleFilterChange(filterTypes.PRODUCT_BRAND, [])}
        />
        <MultiSelect
          key={`spid-${spidOptions.length}`}
          label="Service Provider NPI"
          options={spidOptions}
          selectedOptions={filters.spid}
          onChange={(selected) => handleFilterChange(filterTypes.SPID, selected)}
          onClear={() => handleFilterChange(filterTypes.SPID, [])}
        />
        {hasFiltersApplied && (
          <button className="btn btn--plain" onClick={() => setFilters(INITIAL_FILTERS)}>
            Clear All
          </button>
        )}
        <button
          className="btn btn--primary action-bar__item--right"
          disabled={invoiceStatus !== "Pending Review"}
        >
          Make Overrides
        </button>
      </div>
      <div style={{ position: "relative", left: -1, overflowY: "hidden" }}>
        <TableVirtualized
          tableOptions={tableOptions}
          data={data || []}
          isLoading={isLoading}
          columns={columns}
          filterSetup={filterSetup}
          setFilteredRows={setFilteredRows}
          styleVariant="table--secondary table--layout-fixed"
          noDataMessage="No results found that matched your search"
        />
      </div>
      {isDisplayed && <Notice details={notice} />}
      <div className="table__totals-footer" data-testid="spreadsheet-footer">
        <span className="table__totals-footer__column" style={{ width: 84 }}>
          Total
        </span>
        <span
          className="table__totals-footer__column table__column--align-right-sort"
          style={{ width: 164 }}
        >
          {formatCurrency(totalScrubbedAmount)}
        </span>
        <span
          className="table__totals-footer__column table__column--align-right-sort"
          style={{ width: 124 }}
        >
          {formatCurrency(totalValidatedAmount)}
        </span>
        <span className="table__totals-footer__column table__totals-footer__column--right">
          Row Count: {addCommasToNumber(filteredRows?.length)}
        </span>
      </div>
    </>
  );
};

Spreadsheet.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  invoiceStatus: PropTypes.string.isRequired,
  scrubRules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ),
  isDisplayed: PropTypes.bool.isRequired,
};

export default memo(Spreadsheet);
