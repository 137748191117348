import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

const DateInput = ({ inputId = "", name, value, onChange }) => {
  return (
    <>
      <InputMask
        className="text-input"
        id={inputId}
        name={name}
        value={value}
        mask="99 / 99 / 9999"
        placeholder="MM / DD / YYYY"
        maskChar={null}
        onChange={onChange}
      />
    </>
  );
};

DateInput.propTypes = {
  inputId: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateInput;
