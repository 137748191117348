import React from "react";
import PropTypes from "prop-types";
import { Add, ArrowRight } from "../common/icons";
import { formatCurrency, formatToPercent } from "../utils/numberUtils";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";
import { formatDateLong } from "../utils/dateTimeUtils";

const RecentInvoices = ({ recentInvoices = {} }) => {
  const sections = [
    {
      key: "pending-review-section",
      title: "Invoices Pending Review",
      data: recentInvoices?.pendingReview,
      footerText: "Date Uploaded:",
      footerDateKey: "createdAt",
    },
    {
      key: "finalized-section",
      title: "Recently Finalized",
      data: recentInvoices?.finalized,
      footerText: "Date Finalized:",
      footerDateKey: "finalizedAt",
    },
  ];

  return (
    <div className="card card--auto-height" data-testid="recent-invoices">
      <div className="card__title">Recent Invoices</div>
      <a className="btn" href="/invoices/new" style={{ marginBottom: 24 }}>
        <Add color={colors.white} size={20} />
        <span>Add New Invoice</span>
      </a>
      <div className="info-card__container" style={{ gap: 24, overflow: "auto" }}>
        {sections.map(({ key, title, data, footerText, footerDateKey }) => (
          <div key={key} className="info-card__container">
            <div className="info-card__container__title">{title}</div>
            {data?.map((invoice) => (
              <div key={invoice.id} className="info-card">
                <div className="info-card__details">
                  <div className="info-card__details__text">
                    {invoice.customer.name} {invoice.number}
                  </div>
                  <div className="info-card__details__subtext">
                    <span>{formatCurrency(invoice.totalAmount)}</span>
                    <span>{formatToPercent(invoice.scrubRate, 1)} Scrubbed</span>
                  </div>
                  <div className="info-card__details__footer">
                    <span>
                      {footerText} {formatDateLong(invoice[footerDateKey])}
                    </span>
                  </div>
                </div>
                <a
                  className="icon-btn icon-btn--secondary"
                  href={`/invoices/${invoice.id}`}
                  style={{ flexShrink: 0 }}
                >
                  <ArrowRight size={20} />
                </a>
              </div>
            ))}
          </div>
        ))}
        <a className="btn btn--tertiary" href="/invoices">
          View All Invoices
        </a>
      </div>
    </div>
  );
};

RecentInvoices.propTypes = {
  recentInvoices: PropTypes.shape({
    pendingReview: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        scrubRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        createdAt: PropTypes.string.isRequired,
        customer: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ).isRequired,
    finalized: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        scrubRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        finalizedAt: PropTypes.string.isRequired,
        customer: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ).isRequired,
  }),
};

export default RecentInvoices;
