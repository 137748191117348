import React, { useState, useEffect } from "react";
import useFetch from "../hooks/useFetch";
import Notice from "../common/Notice";
import Bar from "../common/charts/Bar";
import { formatCurrency } from "../utils/numberUtils";
import colors from "../../frontend/stylesheets/common/theme/_colors.module.scss";

const { secondary } = colors;

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const ScrubbedAmounts = () => {
  const [notice, setNotice] = useState(initialNoticeState);
  const { data, isLoading, error } = useFetch("/api/dashboard/scrubbed_amounts_by_quarter");

  useEffect(() => {
    if (error) {
      setNotice({
        ...initialNoticeState,
        open: true,
        message: "Error retrieving scrub amounts data",
      });
    }
  }, [error]);

  const renderTooltip = ({ data: { quarter, amount } }) => (
    <div className="tooltip--position-bottom">
      <div className="tooltip__content">
        <div className="tooltip__content__title">{quarter}</div>
        <div className="tooltip__content__details__row">
          <span
            className="tooltip__content__details__symbol"
            style={{ backgroundColor: secondary }}
          />
          <span>{formatCurrency(amount)} Scrubbed</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="card card--auto-height" data-testid="scrubbed-amounts">
      <div className="card__title">Scrubbed Amounts</div>
      <div style={{ minHeight: 0, flexGrow: 1 }}>
        <Bar
          isLoading={isLoading}
          data={data || []}
          indexBy="quarter"
          keys={["amount"]}
          colors={[secondary]}
          valueType="currency"
          height="100%"
          renderTooltip={renderTooltip}
          noDataMessage="No data was retrieved"
        />
      </div>
      <Notice details={notice} />
    </div>
  );
};

export default ScrubbedAmounts;
