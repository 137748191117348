import React from "react";
import { PropTypes } from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Calendar from "./icons/Calendar";

const DateSelector = ({ name = "", id = "", selected = null, onChange }) => {
  return (
    <DatePicker
      id={id}
      name={name}
      wrapperClassName="react-datepicker__wrapper"
      selected={selected}
      placeholderText="MM/DD/YYYY"
      dateFormat="MM/dd/yyyy"
      onChange={onChange}
      icon={<Calendar size={20} />}
      showIcon
    />
  );
};

DateSelector.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

export default DateSelector;
