import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ScrubResultsChart from "./ScrubResultsChart";
import ScrubResultsTable from "./ScrubResultsTable";
import useFetch from "../../hooks/useFetch";
import Notice from "../../common/Notice";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const COLORS = [
  colors.darkTeal,
  colors.peachOrange,
  colors.purpleBlue,
  colors.goldenYellow,
  colors.lightPurple,
  colors.lightBlue,
  colors.highlightBlue,
];

const initialNoticeState = {
  kind: "error",
  open: false,
  message: "",
};

const ScrubResultsCard = ({ customerId }) => {
  const [notice, setNotice] = useState(initialNoticeState);
  const { data, isLoading, error } = useFetch(`/api/customer_report/${customerId}/invoices`);

  const firstBarColorIndex = -data?.chart?.keys.length || 0;
  const barColors = COLORS.slice(firstBarColorIndex);

  useEffect(() => {
    if (error) {
      setNotice({
        ...initialNoticeState,
        open: true,
        message: "Error retrieving scrub results data",
      });
    }
  }, [error]);

  return (
    <>
      <div className="card">
        <div className="card__title">Scrub Results of Last 4 Invoices</div>
        <div className="cards-container cards-container--row">
          <ScrubResultsChart data={data?.chart} isLoading={isLoading} colors={barColors} />
          <ScrubResultsTable data={data?.table} isLoading={isLoading} colors={barColors} />
        </div>
      </div>
      <Notice details={notice} />
    </>
  );
};

ScrubResultsCard.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default ScrubResultsCard;
