import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import InvoiceMappingTable from "./InvoiceMappingTable";
import { ArrowForward, ArrowLeft, InfoOutlined } from "../../common/icons";
import ToggleSwitch from "../../common/ToggleSwitch";
import SelectSearch from "../../common/SelectSearch";
import Tooltip from "../../common/Tooltip";
import colors from "../../../frontend/stylesheets/common/theme/_colors.module.scss";

const { secondaryText } = colors;

const RAW = "RAW";
const REO = "REO";
const EMPTY_VALUE_PLACEHOLDER = "--";

const MapData = ({
  data,
  reoHeaders,
  fileHeaders,
  headerRowIndex,
  mappingSelections,
  setMappingSelections,
}) => {
  const [columnMappingIsOpen, setColumnMappingIsOpen] = useState(true);
  const [selectedTable, setSelectedTable] = useState(RAW);
  const [selectedRawFileHeaderColumns, setSelectedRawFileHeaderColumns] = useState({});
  const [selectedRawColumnIndex, setSelectedRawColumnIndex] = useState();
  const [selectedReoColumnIndex, setSelectedReoColumnIndex] = useState();
  const [reoTableData, setReoTableData] = useState(
    Array.from({ length: data.length - (headerRowIndex + 1) }, () =>
      Array.from({ length: reoHeaders.length }, () => EMPTY_VALUE_PLACEHOLDER),
    ),
  );

  const rawTableScrollLeftPositionRef = useRef(0);
  const reoTableScrollLeftPositionRef = useRef(0);

  const getReoColumnLabelFromValue = (value) =>
    reoHeaders.find((rHeader) => rHeader.value === value)?.label;

  const EMPTY_HEADER = "<empty>";

  const headerOptions = fileHeaders.map((header) => ({
    label: header || EMPTY_HEADER,
    value: header || EMPTY_HEADER,
  }));

  const handleMappingSelection = (option, header, index) => {
    const rawDataIndex = fileHeaders.indexOf(option);
    const columnData = data.slice(headerRowIndex + 1).map((row) => row[rawDataIndex]);
    const isEmptyOption = !option;

    setMappingSelections((prevSelections) => {
      if (!option) {
        // eslint-disable-next-line no-unused-vars
        const { [header]: _, ...rest } = prevSelections;

        return rest;
      } else {
        return {
          ...prevSelections,
          [header]: option,
        };
      }
    });

    setSelectedRawFileHeaderColumns((prevRawFileHeaderColumns) => {
      let result = {};

      fileHeaders.forEach((fileHeader, index) => {
        for (const [reoHeader, mappedHeader] of Object.entries(mappingSelections)) {
          if (mappedHeader === fileHeader) {
            result[index] = getReoColumnLabelFromValue(reoHeader);
            break;
          }
        }
      });

      if (isEmptyOption) {
        const reoColumnLabelToRemove = getReoColumnLabelFromValue(header);

        result = Object.fromEntries(
          Object.entries(prevRawFileHeaderColumns).filter(
            ([, value]) => value !== reoColumnLabelToRemove,
          ),
        );
      }

      return result;
    });

    const getUpdatedReoTableData = () => {
      columnData.forEach((dataPoint, dataPointIndex) => {
        reoTableData[dataPointIndex][index] = dataPoint || EMPTY_VALUE_PLACEHOLDER;
      });

      return reoTableData;
    };

    setReoTableData(getUpdatedReoTableData());
  };

  const handleMappingSelectionChange = (option, value, index) => {
    setSelectedRawColumnIndex(fileHeaders.indexOf(option));
    setSelectedReoColumnIndex(reoHeaders.findIndex((reoHeader) => reoHeader.value === value));
    handleMappingSelection(option, value, index);
  };

  const renderMappingPairs = () =>
    reoHeaders.map(({ value, label, description }, index) => {
      const mapping = mappingSelections[value];

      const selectedValues = Object.values(mappingSelections).filter((key) => key !== mapping);

      const availableOptions = headerOptions.filter(
        (option) => !selectedValues.includes(option.value),
      );

      useEffect(() => {
        if (mapping) {
          handleMappingSelection(mapping, value, index);
        }
      }, [mapping, value, index]);

      return (
        <div className="column-mapping__main" data-testid="column-mapping-main" key={label}>
          <div className="column-mapping__main__column-1">
            <div className="column-mapping__main__column-1__label">
              <div>{label}</div>
              {description && (
                <Tooltip
                  trigger={
                    <span
                      className="column-mapping__main__column-1__label__icon"
                      data-testid="info-icon"
                    >
                      <InfoOutlined color={secondaryText} size={20} />
                    </span>
                  }
                >
                  <h4 className="tooltip__content__title">{label}</h4>
                  <p>{description}</p>
                </Tooltip>
              )}
            </div>
            <ArrowForward color={secondaryText} size={20} />
          </div>
          <div className="column-mapping__main__column-2">
            <SelectSearch
              key={index}
              testId="column-mapping-select"
              options={availableOptions}
              emptyOptionLabel="Choose Column"
              defaultValue={{ value: "", label: "Choose Column" }}
              value={{ value: mapping, label: mapping || "Choose Column" }}
              placeholder="Choose Column"
              onChange={(option) => handleMappingSelectionChange(option.value, value, index)}
            />
          </div>
        </div>
      );
    });

  const renderColumnMappings = () => (
    <div
      className={`column-mapping ${!columnMappingIsOpen ? "column-mapping--closed" : ""}`}
      data-testid="column-mapping"
    >
      <div className="column-mapping__heading">Column Mapping</div>
      <div
        className={`column-mapping__toggle ${!columnMappingIsOpen ? "column-mapping__toggle--closed" : ""}`}
      >
        <button onClick={() => setColumnMappingIsOpen(!columnMappingIsOpen)}>
          <span data-testid="column-mapping-toggle">
            <ArrowLeft size={20} />
          </span>
        </button>
      </div>
      <div className="column-mapping__subheading__container">
        <div className="column-mapping__subheading">
          <div className="column-mapping__subheading__column-1">
            <div>REO Invoice Columns</div>
          </div>
          <div>Raw File Columns</div>
        </div>
      </div>
      <div className="column-mapping__container">{renderMappingPairs()}</div>
    </div>
  );

  const switchSelectedTable = () => {
    setSelectedTable((prevState) => (prevState === RAW ? REO : RAW));
  };

  const selectedTableProps = () => {
    if (selectedTable === RAW) {
      return {
        data: data.slice(headerRowIndex + 1),
        headers: fileHeaders.map((header) => ({ label: header })),
        selectedColumnIndex: selectedRawColumnIndex,
        selectedColumns: selectedRawFileHeaderColumns,
        tableScrollLeftPositionRef: rawTableScrollLeftPositionRef,
      };
    }

    return {
      data: reoTableData,
      headers: reoHeaders,
      selectedColumnIndex: selectedReoColumnIndex,
      tableScrollLeftPositionRef: reoTableScrollLeftPositionRef,
    };
  };

  return (
    <>
      {renderColumnMappings()}
      <div
        className="table__container table__container--gray-bg"
        style={{ boxShadow: "0 0 15px 0 rgb(0 0 0 / 8%)", paddingBottom: "16px" }}
      >
        <div
          className="action-bar action-bar--justify-content-space-between"
          style={{ margin: "8px 0 12px 0" }}
        >
          <div className="action-bar__text">File Preview</div>
          <ToggleSwitch options={["Raw File", "REO Invoice"]} onSelection={switchSelectedTable} />
        </div>
        <InvoiceMappingTable key={selectedTable} {...selectedTableProps()} />
      </div>
    </>
  );
};

MapData.propTypes = {
  data: PropTypes.array.isRequired,
  reoHeaders: PropTypes.arrayOf(PropTypes.object).isRequired,
  fileHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  headerRowIndex: PropTypes.number.isRequired,
  mappingSelections: PropTypes.object.isRequired,
  setMappingSelections: PropTypes.func.isRequired,
};

export default MapData;
