const reoInvoiceColumns = [
  {
    value: "ndc11",
    label: "NDC",
    width: 96,
  },
  {
    value: "product_name",
    label: "Product Name",
    description: "Product name or description",
    width: 150,
  },
  {
    value: "j_code",
    label: "HCPCS Code",
    description: "J-Code / Procedure Code",
    width: 120,
  },
  {
    value: "j_code_modifier",
    label: "HCPCS Modifier",
    description: "J-Code Modifier 1",
    width: 140,
  },
  {
    value: "date_of_service",
    label: "Date Of Service",
    description: "Date of service or fill date",
    type: "date",
    width: 120,
  },
  {
    value: "quantity",
    label: "Quantity",
    type: "number",
    width: 96,
  },
  {
    value: "service_provider_npi",
    label: "Service Provider NPI",
    description: "10-digit NPI of Facility",
    width: 140,
  },
  {
    value: "secondary_npi",
    label: "Secondary NPI",
    description: "Additional NPI information such as the prescriber or billing provider",
    width: 120,
  },
  {
    value: "plan_reimbursed_amount",
    label: "Plan Reimbursed Amount",
    description: "Amount paid by the health plan",
    type: "currency",
    width: 180,
  },
  {
    value: "requested_rebate_amount",
    label: "Total Requested Rebate Amount",
    description: "Total rebate amount being invoiced by the customer",
    type: "currency",
    width: 220,
  },
  {
    value: "payor_claim_number",
    label: "Payor Claim Number",
    description: "Claim number assigned by the payer",
    width: 160,
  },
  {
    value: "provider_claim_number",
    label: "Provider Claim Number",
    description: "Claim number assigned by the provider facility",
    width: 180,
  },
  {
    value: "encrypted_patient_id",
    label: "Encrypted Patient ID Code",
    width: 170,
  },
  {
    value: "plan_id",
    label: "Plan ID",
    width: 96,
  },
  {
    value: "plan_name",
    label: "Plan Name",
    width: 96,
  },
  {
    value: "client_id",
    label: "Client ID",
    description: "GPO Member Client ID",
    width: 96,
  },
  {
    value: "client_name",
    label: "Client Name",
    description: "GPO Member Client Name",
    width: 120,
  },
  {
    value: "place_of_service",
    label: "Place of Service Code",
    description: "e.g., “11” (Office)",
    width: 160,
  },
  {
    value: "formulary_tier",
    label: "Invoiced Coverage Status",
    description: "e.g., 1 of 1, 1 of 2, etc.",
    width: 180,
  },
  {
    value: "unit_of_measure",
    label: "Unit of Measure",
    description: "e.g., UN, ML, EA",
    width: 140,
  },
];

export default reoInvoiceColumns;
