import React from "react";
import PropTypes from "prop-types";
import Bar from "../../common/charts/Bar";
import { formatCurrency, formatToPercent } from "../../utils/numberUtils";
import { chartTextStyle } from "../../common/charts/themes";

const ScrubResultsChart = ({ data, isLoading, colors }) => {
  const renderTooltip = ({ data: tooltipData }) => (
    <div className="tooltip--position-bottom">
      <div className="tooltip__content" style={{ width: 260 }}>
        <div className="tooltip__content__title">{tooltipData.invoice}</div>
        <div className="tooltip__content__details">
          {data?.keys?.map((key, index) => {
            const scrubbedAmount = tooltipData[key];
            const dollars = scrubbedAmount ? formatCurrency(scrubbedAmount) : "$0";
            const percent = scrubbedAmount
              ? formatToPercent(scrubbedAmount / tooltipData.totalInvoiceAmount)
              : "N/A";
            return (
              <div className="tooltip__content__details__row" key={key}>
                <span
                  className="tooltip__content__details__symbol"
                  style={{ backgroundColor: colors[index] }}
                />
                <span>{`${dollars} ${key} | ${percent}`}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const renderCustomBottomTick = (tick, data) => {
    return (
      <g
        transform={`translate(${tick.x},${tick.y + 16})`}
        style={{ fontSize: chartTextStyle.fontSize }}
        fill={colors.primaryText}
      >
        <text textAnchor="middle">{tick.value}</text>
        <text transform={`translate(0, 16)`} textAnchor="middle">
          {data?.[tick.tickIndex]?.date}
        </text>
      </g>
    );
  };

  return (
    <div style={{ width: "calc(40% - 48px)" }}>
      <Bar
        data={data?.data || []}
        height={422}
        isLoading={isLoading}
        indexBy="invoice"
        keys={data?.keys || []}
        colors={colors}
        valueType="currency"
        multilineTick={true}
        renderTooltip={renderTooltip}
        renderCustomBottomTick={(tick) => renderCustomBottomTick(tick, data?.data)}
        noDataMessage="No data was retrieved."
      />
    </div>
  );
};

ScrubResultsChart.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
};

export default ScrubResultsChart;
