import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";
import useFetch from "../../hooks/useFetch";
import CalculationTable from "../../common/CalculationTable";
import Notice from "../../common/Notice";

const SummaryCard = ({ invoiceId }) => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "Error retrieving summary data",
  });
  const { data, isLoading, error } = useFetch(`/api/invoices/${invoiceId}/summary`);

  useEffect(() => {
    if (error) setNotice((prevObj) => ({ ...prevObj, open: true }));
  }, [error]);

  return (
    <>
      <div className="card card--width-40pct" style={{ minWidth: 430 }} data-testid="summary">
        <div className="card__title">Scrub Summary</div>
        {isLoading && <Skeleton variant="rounded" height={366} />}
        {data && (
          <CalculationTable
            headerLabel="Scrubbed Amount"
            headerValue={data?.scrubbedAmount}
            items={data?.scrubs.map(({ scrubType, scrubbedAmount }) => ({
              label: scrubType,
              amount: scrubbedAmount,
            }))}
          />
        )}
      </div>
      <Notice details={notice} />
    </>
  );
};

SummaryCard.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default SummaryCard;
