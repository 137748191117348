import React from "react";
import PropTypes from "prop-types";
import MetricCards from "./MetricCards";
import SelectSearch from "../common/SelectSearch";
import ScrubResultsCard from "./invoices/ScrubResultsCard";
import ProductCards from "./products/ProductCards";
import PlanCards from "./plans/PlanCards";

const CustomerReport = ({ customers = [], customerId }) => {
  const customerOptions = customers?.map(({ id, name }) => ({ value: id, label: name }));
  const selectedCustomerOption = customerOptions?.find(({ value }) => value === customerId);

  function handleCustomerSelect({ value }) {
    if (value === customerId) return;

    Turbo.visit(`/customer_report/${value}`);
  }

  return (
    <>
      <div className="main-content__header">
        <div className="main-content__header__row">
          <div className="main-content__heading">Customer Report</div>
          <SelectSearch
            inputId="customer"
            name="customer"
            testId="customer-select"
            options={customerOptions}
            defaultValue={customerOptions[0]}
            value={selectedCustomerOption || customerOptions[0]}
            size="md"
            onChange={handleCustomerSelect}
            disableDefaultSort
          />
        </div>
      </div>
      <div className="main-content__body">
        <div className="cards-container">
          <MetricCards customerId={customerId} />
          <ScrubResultsCard customerId={customerId} />
          <ProductCards customerId={customerId} />
          <PlanCards customerId={customerId} />
        </div>
      </div>
    </>
  );
};

CustomerReport.propTypes = {
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  customerId: PropTypes.string.isRequired,
};

export default CustomerReport;
