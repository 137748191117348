import React from "react";
import PropTypes from "prop-types";

const Badge = ({ color = "", enableHover = false, children }) => {
  const colorClass = color ? `badge__indicator--${color}` : "";

  return (
    <div className={`badge ${enableHover ? "badge--hover" : ""}`}>
      <div className={`badge__indicator ${colorClass}`} />
      {children}
    </div>
  );
};

Badge.propTypes = {
  color: PropTypes.oneOf(["dark-green", "bright-purple", "golden-yellow", "notice"]),
  enableHover: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

export default Badge;
