import React from "react";
import PropTypes from "prop-types";

const NameValuePair = ({ name, value, isLargeValue = false, testId = "" }) => (
  <div data-testid={testId}>
    <div className="name-value-pair__name">{name}</div>
    <div
      className={`name-value-pair__value ${isLargeValue ? "name-value-pair__value--large" : ""}`}
    >
      {value}
    </div>
  </div>
);

NameValuePair.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isLargeValue: PropTypes.bool,
  testId: PropTypes.string,
};

export default NameValuePair;
