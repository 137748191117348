import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Label } from "@radix-ui/react-label";
import SelectSearch from "../../common/SelectSearch";
import TextInput from "../../common/inputs/TextInput";
import DateSelector from "../../common/DateSelector";
import FileUpload from "../../common/FileUpload";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isEmptyValue } from "../../utils/validationUtils";

dayjs.extend(customParseFormat);

const NewInvoiceDrawer = ({
  customers,
  getLatestInvoiceData,
  hasAssignedMapping,
  onSubmit,
  showModal,
  setBasicInfo,
  basicInfo,
  handleFileChange,
}) => {
  const [fieldValidity, setFieldValidity] = useState({
    customerId: undefined,
    number: undefined,
    date: undefined,
    dueDate: undefined,
  });
  const [fileUploadIsDisabled, setFileUploadIsDisabled] = useState(true);
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);

  useEffect(() => {
    setFileUploadIsDisabled(Object.values(fieldValidity).some((field) => !field));
  }, [fieldValidity]);

  useEffect(() => {
    setSubmitIsDisabled(fileUploadIsDisabled || !basicInfo.stored_file || !hasAssignedMapping);
  }, [fileUploadIsDisabled, basicInfo, hasAssignedMapping]);

  const customerList = customers.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const handleValidationChange = (name, isValid) => {
    setFieldValidity((prevFieldValidity) => ({
      ...prevFieldValidity,
      [name]: isValid,
    }));
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    setBasicInfo({
      ...basicInfo,
      number: value,
    });

    const isValid = !isEmptyValue(value);
    handleValidationChange("number", isValid);
  };

  const handleCustomerChange = ({ value }) => {
    setBasicInfo({
      ...basicInfo,
      customerId: value,
    });

    const isValid = !isEmptyValue(value);
    handleValidationChange("customerId", isValid);

    value && getLatestInvoiceData(value);
  };

  const handleDateChange = (name, date) => {
    setBasicInfo({
      ...basicInfo,
      [name]: date,
    });

    const isValid = dayjs(date).isValid();

    handleValidationChange(name, isValid);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setSubmitIsDisabled(true);
    onSubmit(basicInfo);
  };

  const { customerId, number, date, dueDate, stored_file } = basicInfo;

  const handleCancel = () => {
    const displayDiscardWarning = [customerId, number, date, dueDate, stored_file].some(
      (field) => field,
    );

    if (displayDiscardWarning) return showModal("discard");

    return Turbo.visit("/invoices");
  };

  return (
    <div className="form">
      <div className="form__heading">Basic Information</div>
      <form className="form__content">
        <div className="form__content__item">
          <Label className="form__content__label" htmlFor="customer">
            Customer
          </Label>
          <SelectSearch
            inputId="customer"
            testId="customer-select"
            options={customerList}
            emptyOptionLabel="Choose Customer"
            placeholder="Choose Customer"
            onChange={handleCustomerChange}
            defaultValue={{ value: "", label: "Choose Customer" }}
            value={{
              value: customerId,
              label:
                customerList.find((customer) => customer.value === customerId)?.label ||
                "Choose Customer",
            }}
          />
        </div>
        <div className="form__content__item">
          <label className="form__content__label" htmlFor="number">
            Invoice Number
          </label>
          <TextInput
            inputId="number"
            name="number"
            placeholder="000000"
            value={number}
            onChange={handleInputChange}
          />
        </div>
        <div className="form__content__item">
          <label className="form__content__label" htmlFor="date">
            Invoice Date
          </label>
          <DateSelector
            id="date"
            name="date"
            selected={date}
            onChange={(date) => handleDateChange("date", date)}
          />
        </div>
        <div className="form__content__item">
          <label className="form__content__label" htmlFor="dueDate">
            Invoice Due Date
          </label>
          <DateSelector
            id="dueDate"
            name="dueDate"
            selected={dueDate}
            onChange={(date) => handleDateChange("dueDate", date)}
          />
        </div>
        <hr className="form__divider" />
        <div
          className={`file-upload__container ${basicInfo.stored_file ? "file-upload__container--file-attached" : ""}`}
        >
          <div className="form__heading form__heading--inline">My Upload</div>
          <FileUpload
            buttonLabel="Attach File"
            stored_file={stored_file}
            onChange={handleFileChange}
            onRemove={() => showModal("goBack")}
            acceptedFileTypes=",.csv,.txt,.tsv,.xls,.xlsx"
            disabled={fileUploadIsDisabled}
          />
        </div>
        <hr className="form__divider" />
        <div className="form__actions">
          <button className="btn btn--secondary" type="button" onClick={handleCancel}>
            Cancel
          </button>
          <button
            className="btn btn--grow"
            style={{ marginLeft: "24px" }}
            type="submit"
            disabled={submitIsDisabled}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

NewInvoiceDrawer.propTypes = {
  customers: PropTypes.array.isRequired,
  getLatestInvoiceData: PropTypes.func.isRequired,
  hasAssignedMapping: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  setBasicInfo: PropTypes.func.isRequired,
  basicInfo: PropTypes.object.isRequired,
  handleFileChange: PropTypes.func.isRequired,
};

export default NewInvoiceDrawer;
